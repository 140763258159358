import { Buttons } from "@/ui/Buttons";
import { ParallaxBackground } from "@/ui/ParallaxBackground";
import { Tagline } from "@/ui/Tagline";
import { Col, Container, Row } from "react-bootstrap";

import cn from "classnames";
import bg_1 from "./bg_1.webp";
import bg_2 from "./bg_2.webp";
import classes from "./styles.module.scss";

export default function ({
  className = "py-5 pt-md-0 pb-md-6",
  tagline,
  title,
  content,
  buttons,
  EditableText,
  EditableHtml,
  theme = "light",
}) {
  return (
    <div className={cn(className, classes.HomeAboutContent)} data-bs-theme={theme}>
      <ParallaxBackground anim="y2n:x0:z1" image={bg_1} className={classes.bg_1} />
      <ParallaxBackground anim="y1:x2:z0" image={bg_2} className={classes.bg_2} />
      <Container fluid="md">
        <Row>
          <Col xs={12}>
            <Tagline as={EditableText} path="tagline" tag="strong" className="d-block mb-3">
              <span dangerouslySetInnerHTML={{ __html: tagline }} />
            </Tagline>
            <EditableText path="title" tag="h2" className="mb-4 mb-md-5">
              {title}
            </EditableText>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <Buttons buttons={buttons} />
          </Col>
          <Col xs={12} md={8}>
            <EditableHtml path="content">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </EditableHtml>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
